
  // import StepComponent from '~/components/Step'

  export default {
    name: `MarketplaceExternalPage`,
    components: {  },
    options: {
      auth: false
    },
    data() {
      return {
        form: [],
        screener: null,
        lang: null,
        steps: null,
        headerMessage: null,
        numberStep: 3,
        currentStep: 1,
        formSent: false,
        message: undefined,
        status: null,
        answers: {}
      }
    },
    async fetch() {
      try {
        const {
          _data: { lang, form, answers }
        } = await this.$store.dispatch(`marketplaceExternal/getExternal`)
        this.lang = lang
        this.form = form
        this.answers = answers
        // reset all answers

        for (const element in form) {
          this.resetAnswer(element)
        }
      } catch (err) {
        console.log(err)
        this.status = `error`
        if (err?.response?.status === 401) {
          this.message = err.data.detail
        } else {
          throw new Error(err)
        }
      }
    },
    computed: {
      token() {
        return this.$router.history.current.query.token || this.$store.state.external.token
      },
      orderedFormElements() {
        return this.$helpers.sortElements(this.form, (a, b) => a[1].order - b[1].order)
      }
    },
    watch: {
      lang(value) {
        this.$i18n.setLocale(value)
      },
      form(value) {
        this.key += 1
      }
    },
    created() {
      if (!this.$router.history.current.query.token) {
        return null
      }
      this.$store.commit(`marketplaceExternal/setToken`, this.$router.history.current.query.token)
    },
    mounted() {},
    updated() {},
    methods: {
      gotoStep(index) {
        this.currentStep = index
      },
      isDisplayed(question) {
        const display = this.$shared.isDisplayed(question, this.answers)
        if (!display && question && question.title && this.answers[question.title] !== undefined) {
          this.resetAnswer(question.title);
        }
        return display
      },
      resetAnswer(name) {
        if (this.form[name] && this.form[name].options?.initial?.value) {
          this.saveAnswers(name, this.form[name].options?.initial?.value)
        } else {
          this.saveAnswers(name, undefined)
        }
      },
      saveAnswers(name, value) {
        this.answers[name] = value
      },
      async submitForm() {
        const valid = await this.$refs.marketplaceExternalFormObs.validate()
        if (!valid) {
          this.gotoStep(2);
          return
        }
        this.$store.dispatch(`marketplaceExternal/submitForm`, {'form': this.answers})
          .then( res => {this.formSent = true})
          .catch( err => {this.gotoStep(2);
            this.$flashMessage.error({
              title: this.$i18n.t(`messages.error`),
              message: err?.data?.message
            })
          })
      }
    }
  }
